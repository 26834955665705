<template>
  <div
    class="
      widgetContainer
      header-white
      widgetContainer--scrollable
      checkDeposit-preview
    ">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="backAction" />
        <p class="title">
          {{ $t('RCD_captureScreen_navTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <div class="preview-details">
        <div class="preview-details__inner">
          <p class="preview-details__inner__label">
            {{ $t('RCD_check_amount') }}:
          </p>
          <p class="preview-details__inner__value">
            {{ getCheckDepositFormData.checkData.amount | currency }}
          </p>
        </div>
        <div class="preview-details__inner">
          <p class="preview-details__inner__label">
            {{ $t('RCD_deposit_to') }}:
          </p>
          <p class="preview-details__inner__value">
            <span class="preview-details__inner__value__legal-name" v-if="isBusinessAccountType">
              {{ getSelectedBusiness.legalName }}
            </span>
            <span class="account-label">
              {{ getSelectedAccount.label }}
            </span>
          </p>
        </div>
      </div>
      <div class="preview-details uploadCheckImage">
        <div class="uploadCheckImage__header checkDeposit">
          <el-checkbox disabled v-model="isChecked" />
          <p>
            {{ $t('RCD_frontTitle') }}
          </p>
        </div>
        <div class="uploadCheckImage__image">
          <img
            v-if="getCheckDepositFormData.files.front.type.startsWith('image/')"
            :src="frontFile"
            alt="image">
          <embed
            v-else-if="
              getCheckDepositFormData.files.front.type === 'application/pdf'
            "
            :src="frontFile"
            width="300px"
            height="190px">
        </div>
      </div>

      <div class="preview-details uploadCheckImage">
        <div class="uploadCheckImage__header checkDeposit">
          <el-checkbox disabled v-model="isChecked" />
          <p>
            {{ $t('RCD_backTitle') }}
          </p>
        </div>
        <div class="uploadCheckImage__image">
          <img
            v-if="getCheckDepositFormData.files.back.type.startsWith('image/')"
            :src="backFile"
            alt="image">
          <embed
            v-else-if="
              getCheckDepositFormData.files.back.type === 'application/pdf'
            "
            :src="backFile"
            width="300px"
            height="190px">
        </div>
      </div>
    </div>
    <div class="el-drawer__footer">
      <el-button type="primary" class="submit el-button__brand brand width-100" @click="submit">
        {{ $t('RCD_deposit') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  created() {
    if (this.getCheckDepositFormData === null) {
      this.drawerPush('CheckDeposit-form');
      // this.$router.push('/check-deposit/form');
    }
  },
  data() {
    return {
      isChecked: true
    };
  },
  computed: {
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('user',['getPersonDetails']),
    ...mapGetters('checkDeposit', [
      'getSelectedContact',
      'getCheckDepositFormData'
    ]),
    ...mapGetters('account', ['getSelectedAccount', 'isBusinessAccountType']),
    frontFile() {
      return URL.createObjectURL(this.getCheckDepositFormData.files.front);
    },
    backFile() {
      return URL.createObjectURL(this.getCheckDepositFormData.files.back);
    }
  },
  methods: {
    ...mapActions('checkDeposit', [
      'createCheckDeposit',
      'updateCheckDepositFiles'
    ]),
    ...mapMutations('checkDeposit', [
      'setSuccessDepositData',
      'setCheckDepositFormData'
    ]),
    backAction() {
      // this.goToPreviousScreen();
      this.drawerBack();
    },
    submit() {
      const payload = this.getCheckDepositFormData.checkData;
      const loader = this.showLoader();
      this.createCheckDeposit({
        ...payload,
        amount: payload.amount.toFixed(2).toString()
      })
        .then((res) => {
          this.setSuccessDepositData(res.data);
          const file1 = this.getCheckDepositFormData.files.front;
          const file2 = this.getCheckDepositFormData.files.back;
          if (file1 && file2) {
            const formData = new FormData();
            formData.append('front', file1);
            formData.append('back', file2);
            formData.append('accountId', this.getSelectedAccount.id);
            this.updateCheckDepositFiles({
              id: res.data.id,
              data: formData
            })
              .then((res2) => {
                this.setSuccessDepositData(res2.data);
                this.setCheckDepositFormData(null);
                // this.$router.replace('/check-deposit/success');
                this.drawerPush('CheckDeposit-success');
              })
              .catch((e) => {
                console.log(e);
                this.apiErrorMessage(e);
              })
              .finally(() => {
                loader.close();
              });
          } else {
            // this.$router.replace('/check-deposit/success');
            this.drawerPush('CheckDeposit-success');
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
          loader.close();
        });
    }
  }
};
</script>

<style lang="scss">
.checkDeposit-preview {
  .preview-details {
    border-radius: var(--radius);
    background-color: #fff;
    padding: 16px 24px;
    margin-bottom: 16px;
    border: 1px solid #EDEFF1;
    &__inner {
      display: flex;
      &:first-child {
        margin-bottom: 16px;
      }
      &__label {
        width: 50%;
        color: rgba(118, 118, 118, 1);
        font-size: 14px;
      }
      &__value {
        font-size: 14px;
        font-weight: 500;
        color: var(--branding);
        &__legal-name {
          display: inline-block;
          padding-bottom: 6px;
        }
      }
    }

    .account-label {
      color: gray;
      display: block;
    }
  }
}
</style>
